import React from "react";
import { Container } from "../Elements/Container";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image";
const StyledQuote = styled.section`
  padding-top: 4rem;
  padding-bottom: 3rem;
  background: ${(props) => props.color};
  .icon-quotes-open {
    color: #c2c2c2 !important;
    align-self: center;
    font-size: 1.5em;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: calc(${rhythm(2)});
    &:before {
      color: #c2c2c2 !important;
    }
  }
  .pt {
    display: none;
    max-width: 400px;
  }
  .ld {
    max-width: 700px;
    margin: auto;
  }
  @media screen and (orientation: portrait) and (max-width: 550px) {
    .ld {
      display: none !important;
    }
    .pt {
      display: block !important;
    }
  }
  blockquote {
    text-align: center;
    color: white;
    font-weight: 300;
    .content {
      font-size: 1.8rem;
      line-height: 1.3;
      font-family: "Bebas Neue Bold";
      color: #333;
      margin-bottom: 2rem;
      text-align: justify;
    }
    @media screen and (min-width: 769px) {
      max-width: 70vw;
    }
    @media screen and (max-width: 769px) {
      margin-right: ${rhythm(0.5)};
      margin-left: ${rhythm(0.5)};
    }
  }
`;

const QuoteIcon = styled.img`
  margin-bottom: 22px !important;
  width: 40px;
`;

const Testimonial = (props) => {
  return (
    <StyledQuote color={props.color}>
      <Container color={props.color}>
        <blockquote>
          <span>
            <QuoteIcon src="/img/iconmonstr-quote-1.svg"></QuoteIcon>
          </span>
          {ReactHtmlParser(props.content)}
          {props.images.specialCaseImage !== undefined && 
          props.images.specialCaseImage !== null ? (
            <img src={`/img/${props.images.specialCaseImage[0].name}`}
                 width={"440px"}
                 height={"160px"}></img>
          ) : (
            <>
              <Img critical={true}
                  className="pt"
                  fluid={props.images.portrait.childImageSharp.fluid}
              ></Img>
              <Img critical={true}
                   className="ld"
                   fluid={props.images.landscape.childImageSharp.fluid}
              ></Img>
            </>
          )}
        </blockquote>
      </Container>
    </StyledQuote>
  );
};

export default Testimonial;
